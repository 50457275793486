@tailwind base;
@tailwind components;
@tailwind utilities;
 
@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 84% 4.9%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;
 
    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;
 
    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;
 
    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;
 
    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;
 
    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;
 
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;

    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
    --ring: 222.2 84% 4.9%;
 
    --radius: 0.5rem;
  }
 
  .dark {
    --background: 222.2 84% 4.9%;
    --foreground: 210 40% 98%;
 
    --card: 222.2 84% 4.9%;
    --card-foreground: 210 40% 98%;
 
    --popover: 222.2 84% 4.9%;
    --popover-foreground: 210 40% 98%;
 
    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 11.2%;
 
    --secondary: 217.2 32.6% 17.5%;
    --secondary-foreground: 210 40% 98%;
 
    --muted: 217.2 32.6% 17.5%;
    --muted-foreground: 215 20.2% 65.1%;
 
    --accent: 217.2 32.6% 17.5%;
    --accent-foreground: 210 40% 98%;
 
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 210 40% 98%;
 
    --border: 217.2 32.6% 17.5%;
    --input: 217.2 32.6% 17.5%;
    --ring: 212.7 26.8% 83.9%;
  }
}

* {
  list-style: none;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}

.carousel-card {
  margin: 0 8px; /* This sets the horizontal margin to create a gap between cards */
}

/* body {
  font-family: var(--font-poppins)
} */

 body{
  font-family: sans-serif;
 }



.filter-gray {
  filter: brightness(0) saturate(100%) invert(47%) sepia(0%) saturate(217%)
    hue-rotate(32deg) brightness(98%) contrast(92%);
}

.slick-slide > div{
  margin: 0 10px;
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }
}

@layer utilities {
  .wrapper {
    @apply max-w-7xl lg:mx-auto p-5 md:px-10 xl:px-0 w-full;
  }
  .wrapper-dashboard {
    @apply max-w-7xl lg:mx-auto p-5 md:px-16 xl:px-10 2xl:px-2 w-full;
  }

  .wrapperexplore {
    @apply max-w-7xl lg:mx-auto  md:px-10 ;
  }

  .headermenu{
    background-color: #520C17 ;
  }

  /* .bgcolorgold{
    background-color: #D9C255 ;
  }
   */

  .bgcolorselect{
    background-color: #D9C255 ;
  }
  
  .headersticky{
    @apply sticky
  }
  
  .headerabsolute{
    @apply absolute
  }

  .wrapper-weaves {
    @apply lg:mx-auto p-2 md:px-7 w-full;
  }

  .wrapper-newprice {
    @apply lg:mx-auto p-2 px-6  md:px-10 w-full;
  }
  .set-wrap {
    @apply  lg:mx-auto p-2  md:px-4 w-full;
  }
  
  .flex-center {
    @apply flex justify-center items-center;
  }

  .flex-between {
    @apply flex justify-between items-center;
  }

  /* TYPOGRAPHY */
  /* 64 */
  .h1-bold {
    @apply font-bold text-[40px] leading-[48px] lg:text-[48px] lg:leading-[60px]  xl:text-[58px] xl:leading-[74px];
  }

  /* 40 */
  .h2-bold {
    @apply font-bold text-[32px] leading-[40px] lg:text-[36px] lg:leading-[44px] xl:text-[40px] xl:leading-[48px];
  }

  .h2-medium {
    @apply font-medium text-[32px] leading-[40px] lg:text-[36px] lg:leading-[44px] xl:text-[40px] xl:leading-[48px];
  }

  /* 36 */
  .h3-bold {
    @apply font-bold text-[28px] leading-[36px] md:text-[36px] md:leading-[44px];
  }

  .h3-medium {
    @apply font-medium text-[28px] leading-[36px] md:text-[36px] md:leading-[44px];
  }

  /* 32 */
  .h4-medium {
    @apply font-medium text-[32px] leading-[40px];
  }

  /* 28 */
  .h5-bold {
    @apply font-bold md:text-[24px] text-[20px] leading-[36px];
  }

  /* 24 */
  .p-bold-24 {
    @apply font-bold text-[24px] leading-[36px];
  }

  .p-medium-24 {
    @apply font-medium text-[24px] leading-[36px];
  }

  .p-regular-24 {
    @apply font-normal text-[24px] leading-[36px];
  }

  /* 20 */
  .p-bold-20 {
    @apply font-bold text-[20px] leading-[30px] tracking-[2%];
  }

  .p-semibold-20 {
    @apply text-[20px] font-semibold leading-[30px] tracking-[2%];
  }

  .p-medium-20 {
    @apply text-[20px] font-medium leading-[30px];
  }

  .p-regular-20 {
    @apply text-[20px] font-normal leading-[30px] tracking-[2%];
  }

  /* 18 */
  .p-semibold-18 {
    @apply text-[18px] font-semibold leading-[28px] tracking-[2%];
  }

  .p-medium-18 {
    @apply text-[18px] font-medium leading-[28px];
  }

  .p-regular-18 {
    @apply text-[18px] font-normal leading-[28px] tracking-[2%];
  }

  /* 16 */
  .p-bold-16 {
    @apply text-[16px] font-bold leading-[24px];
  }

  .p-medium-16 {
    @apply text-[16px] font-medium leading-[24px];
  }

  .p-regular-16 {
    @apply text-[16px] font-normal leading-[24px];
  }

  /* 14 */
  .p-semibold-14 {
    @apply text-[14px] font-semibold leading-[20px];
  }

  .p-medium-14 {
    @apply text-[14px] font-medium leading-[20px];
  }

  .p-regular-14 {
    @apply text-[14px] font-normal leading-[20px];
  }

  /* 12 */
  .p-medium-12 {
    @apply text-[12px] font-medium leading-[20px];
  }

  /* SHADCN OVERRIDES */
  .select-field {
    @apply w-full bg-gray-50 h-[54px] placeholder:text-gray-500 rounded-full p-regular-16 px-5 py-3 border-none focus-visible:ring-transparent focus:ring-transparent !important;
  }

  
  .input-field {
    @apply bg-gray-100 h-[54px] focus-visible:ring-offset-0 placeholder:text-gray-500  p-regular-16 px-4 py-3 border-none focus-visible:ring-transparent !important;
  }

  .search-field {
    @apply h-[54px] focus-visible:ring-offset-0 placeholder:text-gray-500  p-regular-16 px-4 py-3 border-none focus-visible:ring-transparent !important;
  }

  .textarea {
    @apply bg-gray-50 flex flex-1 h-[100px] placeholder:text-gray-500 p-regular-16 px-5 py-3 border-none focus-visible:ring-transparent !important;
  }

  .button {
    @apply rounded-full h-[54px] p-regular-16;
  }

  .select-item {
    @apply py-3 cursor-pointer  focus:bg-primary;
  }

  .toggle-switch {
    @apply bg-gray-300 !important;
  }
}